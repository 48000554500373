<template>
	<div class="setting-page">
		<a-spin :spinning="loading">
			<div class="ft20 cl-main ftw600">店铺资料</div>

			<div class="mt20 bg-w pd40">
				<div style="width: 1000px;">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item required label="门店logo">
						      <div>
								  <div class="ft12 cl-notice">建议上传200x200px</div>
								  <div class="mt12">
									  <upload-img v-model="form.logo"></upload-img>
								  </div>
							  </div>
						</a-form-model-item>
						<a-form-model-item required label="门店门牌照">
						     <upload-img v-model="form.door_license_plate"></upload-img>
						</a-form-model-item>
						<a-form-model-item required label="门店名称">
							<a-input v-model="form.name" placeholder="输入门店名称" style="width: 400px;"></a-input>
						</a-form-model-item>
						<a-form-model-item required label="门店电话">
							<a-input v-model="form.tel" placeholder="输入门店电话" style="width: 400px;"></a-input>
						</a-form-model-item>
						<a-form-model-item required label="营业时间">
							<a-input v-model="form.business_hours" placeholder="例周一至周日 08:00-20:00" style="width: 400px;"></a-input>
						</a-form-model-item>
						<a-form-model-item required label="门店地址">
							<a-input v-model="form.address"  placeholder="输入门店地址" style="width: 400px;"></a-input>
						</a-form-model-item>
						<a-form-model-item required label="门店简介">
							<a-input v-model="form.info"  placeholder="输入门店简介" type="textarea"></a-input>
						</a-form-model-item>
						<a-form-model-item required label="门店定位">
							<div class="flex alcenter">
								<a-input :value="form.lat + ',' + form.lng" disabled style="width: 200px; margin-right: 10px; color: #000000;"></a-input>
								<baidu-map :lat="form.lat" :lng="form.lng" @select="selectAct"></baidu-map>
							</div>
						</a-form-model-item>
						<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
						      <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
						        确定保存
						      </a-button>
						</a-form-model-item>
					</a-form-model>

				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import uploadImg from '../../../components/upload/upload.vue';
	import BaiduMap from '../../../components/map/baidumap.vue';
	export default{
		components:{
			uploadImg,
			BaiduMap
		},
		data(){
			return {
				loading:false,
				confirmLoading:false,
				labelCol: { span: 4 },
			    wrapperCol: { span: 14 },
				form: {}
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.papi('platform/terminal/showShopDetail').then(res=>{
					this.form=res.detail;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.papi('platform/terminal/saveShopDetail',{
					name:this.form.name,
					logo:this.form.logo,
					door_license_plate:this.form.door_license_plate,
					business_hours:this.form.business_hours,
					tel:this.form.tel,
					address:this.form.address,
					info:this.form.info,
					lng:this.form.lng,
					lat:this.form.lat,
				}).then(res=>{
					this.$message.success('更新成功',1,()=>{
						this.confirmLoading=false;
						this.loaddata();
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			selectAct(e){
				this.form.lat = e.lat;
				this.form.lng = e.lng;
			}
		}
	}
</script>

<style>
	.setting-page{
		min-width: 1080px;
	}
</style>
