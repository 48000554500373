<template>
    <div>
        <div class="wyu-upload-box" @click="showAlbum = true" :class="typeClass">
			<img v-if="imageUrl" width="100%"  :src="imageUrl" alt="avatar" />
			<div v-else>
			    <a-icon type="plus" style="color:#4772FF;font-size: 28px;" />
			</div>
		</div>
		
		<wyu-album v-if="showAlbum" type="single" @closed="closed" @change="changePhoto"></wyu-album>
    </div>
</template>

<script>
import wyuAlbum from './album.vue';
export default {
	components:{
		wyuAlbum
	},
    props:{
		typeClass:{
			type:String,
			default:''
		},
        value:{
            type:String,
            default:''
        },
		
    },
    created(){
        
    },
    computed:{
        imageUrl(){
            return this.value;
        },
       
    },
    data(){
        return {
             showAlbum:false,
			 
        }
    },
    methods:{
		closed(){
			this.showAlbum = false;
		},
		changePhoto(e){
			this.$emit('input',e);
			this.$emit('change',e);
		}
	},
}
</script>

<style>

 .wyu-upload-box {
    width: 128px;
    height: 128px;
	background: rgba(94, 64, 255, 0.06);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 4px;
  }
  .wyu-upload-box:hover{
	  border: 1px dashed #4772FF;
  }
  .wyu-upload-box img{
	  object-fit: cover;
	  width: 114px;
	  height: 114px;
  }
  .wyu-upload-box.idcard{
	  width: 190px;
	  height: 120px;
  }
  .avatar-uploader.idcard > .ant-upload img{
	  width: 176px;
	  height: 106px;
  }
  
  .wyu-upload-box.small{
  	  width: 80px;
  	  height: 80px;
  }
  .wyu-upload-box.small  img{
  	  width: 66px;
  	  height: 66px;
  }
  

  .wyu-upload-box.mobile{
  	width: 750px;
  }
  
  .wyu-upload-box.mobile img {
  	width: 750px;
  }
  

</style>